<template>
  <div>
    <section>
      <video id="slider" muted loop autoplay :src="videoUrl"></video>
      <ul class="navigation">
        <li v-for="item in imgData" :key="item.id" @click="selectUrl(item)">
          <img v-lazy="item.url" alt="你的图片不小心走丢了" />
        </li>
      </ul>
      <h5>{{ title }}</h5>
    </section>
  </div>
</template>

<script>
export default {
  name: '',
  data() {
    return {
      imgData: [
        {
          id: 1,
          url: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/1.jpg',
          title: '碳足迹可不是“脚印”',
          videoUrl:require('@/assets/viedos/1-“碳中和科普动画”第三集：碳足迹可不是“脚印”-1080P 高清-AVC.mp4'),
        },
        {
          id: 2,
          url: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/2.jpg',
          title: '什么是“双碳”目标？',
          videoUrl: require('@/assets/viedos/1-“碳中和科普动画”第二集：什么是“双碳”目标？-1080P 高清-AVC.mp4'),
        },
        {
          id: 3,
          url: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/3.jpg',
          title: '“碳达峰”、“碳中和”科普知识',
          videoUrl: require('@/assets/viedos/1-【科普动画】“碳达峰”、“碳中和”科普知识动画宣传片-1080P 高清-AVC.mp4'),
        },
        {
          id: 4,
          url: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/4.jpg',
          title: '碳中和背后的经济博弈',
          videoUrl:require('@/assets/viedos/1-中国为什么一定要走碳中和之路？碳中和背后的经济博弈！-1080P 高清-AVC.mp4'),
        },
        {
          id: 5,
          url: 'https://qucs.oss-cn-hangzhou.aliyuncs.com/images/5.jpg',
          title: '关于碳达峰碳中和',
          videoUrl: require('@/assets/viedos/1-关于碳达峰碳中和 你该了解的九件事-1080P 高清-AVC.mp4'),
        },
      ],
      title: '碳足迹可不是“脚印”',
      videoUrl:require('@/assets/viedos/1-“碳中和科普动画”第三集：碳足迹可不是“脚印”-1080P 高清-AVC.mp4'),
    };
  },
  methods: {
    selectUrl(item) {
      this.title = item.title;
      this.videoUrl = item.videoUrl;
    },
  },
};
</script>

<style lang="less" scoped>
section {
  position: relative;
  width: 100%;
  height: 120px;
  h5 {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    width: 90%;
    font-size: 14px;
    color: white;
    z-index: 1200;
  }
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .navigation {
    position: absolute;
    bottom: 7%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    li {
      list-style: none;
      cursor: pointer;
      margin: 0 10px;
      opacity: 0.7;
      transition: 0.5s;
      padding: 0;
     img {
        width: 27px;
        height: 17px;
        object-fit: cover;
        transition: 0.5s;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
}
</style>